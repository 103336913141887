// Form Elements
.form-element {
  position: relative;
  margin-bottom: 1rem;

  label {
    display: block;
    padding: 0 0 0.5rem;
    font-size: 1.4rem;
  }

  input,
  textarea {
    display: block;
    width: 100%;
    padding: 1rem 1.5rem;
    color: $gray-800;
    background-color: #fff;
    border: 1px solid $gray-300;
    border-radius: 4px;
    transition: border-color 0.15s ease;
    outline: none;

    &:-webkit-autofill {
      box-shadow: 0 0 0 30px #fff inset !important;
    }

    &:focus {
      border-color: $gray-500;
    }

    &:disabled {
      background-color: $gray-100;
    }
  }

  input {
    &[type="date"],
    &[type="datetime-local"] {
      padding: 0;
    }

    &::-webkit-datetime-edit {
      padding: 0.9rem 1.5rem;
    }
    &::-webkit-calendar-picker-indicator {
      padding: 1rem;
    }
  }

  textarea {
    min-height: 10rem;
    resize: vertical;
  }

  .icon {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.6rem;
    height: 4.6rem;

    &.icon-error path {
      fill: $error;
    }
  }

  // States
  &.error input {
    padding-right: 4rem;
    border-color: $error;
  }
  &.error textarea {
    border-color: $error;
  }

  &.warning input {
    border-color: $warning;
  }

  // Alignment
  &.leftAlign {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    label {
      flex: 0 0 17rem;
      padding: 0;
      padding-right: 1rem;
    }

    .element {
      flex: 1 1 auto;
    }
  }

  // Money Symbol Prefix
  .money-input {
    display: flex;
    flex-wrap: nowrap;

    .symbol {
      flex: 0 0 auto;
      min-width: 4rem;
      padding: 1.3rem 1rem;
      text-align: center;
      background-color: $gray-300;
      color: $gray-800;
      border-radius: 4px 0 0 4px;
      font-size: 2rem;
      line-height: 1;
    }

    input {
      border-radius: 0 4px 4px 0;
    }
  }

  &.textarea.leftAlign {
    align-items: flex-start;
  }

  // Checkbox
  &.checkbox {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    margin-bottom: 1rem;

    input {
      flex: 0 0 auto;
    }

    label {
      flex: 0 1 auto;
      display: block;
      padding: 0.5rem 1rem;
      font-size: 1.4rem;
    }
  }
}

input[type="checkbox"] {
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  color: $gray-800;
  background-color: #fff;
  border: 1px solid $gray-300;
  border-radius: 4px;
  transition: border-color 0.15s ease;

  &:focus {
    border-color: $gray-500;
  }

  &:checked {
    background: #fff url("../../images/tick-primary.svg") center center
      no-repeat;
    background-size: 50% auto;
  }

  &:disabled {
    background-color: $gray-100;
  }
}

// Form Styling
form {
  &.small-form {
    max-width: 40rem;
  }

  &.medium-form {
    max-width: 60rem;
  }

  .col {
    flex: 1 1 100%;
  }

  @media (min-width: 768px) {
    .col {
      flex: 0 1 50%;
      max-width: 50%;
    }
  }

  fieldset {
    legend {
      display: block;
      font-size: 2rem;
      font-weight: 300;
      margin-bottom: 1.5rem;
    }
  }

  .spacer {
    height: 2.6rem;
  }

  .form-actions {
    padding: 2rem 0 0;
  }

  .timestamp {
    line-height: 1.2;
    padding: 1.6rem 0;
    text-align: right;
  }
}

div.leftAlign {
  display: flex;
  flex-wrap: nowrap;

  .label {
    flex: 0 0 17rem;
    padding: 0;
    padding-right: 1rem;
    font-size: 1.4rem;
    font-weight: normal;
  }

  .row {
    flex: 1 1 auto;
  }
}

.modal .form-actions {
  text-align: right;
}

.user-timestamps {
  margin: 1rem -1.5rem 0;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  color: $gray-600;

  > div {
    flex: 1 1 50%;
    padding: 1.5rem;
  }
}

.links {
  padding: 1rem 0;

  a {
    user-select: auto;
    color: $secondary;
    font-weight: normal;
  }
}
