@charset 'utf-8';

@import "./variables";

@import "./components/grid";
@import "./components/Button";
@import "./components/NewButton";
@import "./components/Forms";
@import "./components/Forms/Business/New";
@import "./components/Forms/Business/Contacts";
@import "./components/Forms/Business/FactFind";

.online-booking-form {
  padding: 2rem;

  &__logo {
    display: block;
    width: 100%;
    max-width: 40rem;
    margin: 0 auto 2rem;
  }

  &__text {
    margin-bottom: 2rem;
    text-align: center;
  }

  h2 {
    font-size: 2rem;
    font-weight: normal;
  }

  p {
    font-size: 1.6rem;
  }

  &__form {
    max-width: 70rem;
    margin: 0 auto;
    padding: 2rem;
    border: 1px solid $gray-300;
    border-radius: 5px;
  }

  &__success {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    text-align: center;
    color: $success;
  }

  .form-actions {
    padding-top: 1rem;
    text-align: right;
  }

  @media (min-width: 576px) {
    .form-element {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      label {
        flex: 0 0 17rem;
        padding: 0;
        padding-right: 1rem;
      }

      .element {
        flex: 1 1 auto;
      }

      &.textarea {
        align-items: flex-start;

        label {
          padding-top: 1rem;
        }
      }
    }

    .form-actions {
      padding-left: 17rem;
    }
  }
}
