// Colour Pallette
$gray-100: #f7f7f7;
$gray-200: #eee;
$gray-300: #ddd;
$gray-500: #838c8e;
$gray-600: #666;
$gray-800: #3c3d41;
$gray-900: #2e2e30;

$primary: #277cea;
$secondary: #57b031;
$border: $gray-500;
$light: #eee;
$error: #ff6666;
$success: $secondary;
$orange: #e18f13;
$pink: #a9307a;
$warning: $orange;
