.new-business-wizard {
  header {
    border-bottom: 1px solid $gray-300;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;

    .row {
      align-items: center;
    }
  }

  .form-actions {
    .col {
      flex: 1 1 auto;

      &:nth-child(2) {
        text-align: right;
      }
    }
  }
}
