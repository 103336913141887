.add-new {
  position: relative;
  display: inline-block;
  padding-left: 3rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.7;

  svg {
    position: absolute;
    top: 1px;
    left: 0;
    display: block;
    width: 2rem;
    height: 2rem;
    fill: $primary;
  }

  &:hover {
    text-decoration: underline;
  }
}
