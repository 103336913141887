@charset 'utf-8';

@import "./variables";

@import "./components/grid";
@import "./components/Button";
@import "./components/NewButton";
@import "./components/Forms";
@import "./components/Forms/Business/New";
@import "./components/Forms/Business/Contacts";
@import "./components/Forms/Business/FactFind";

.online-business-form {
  position: relative;
  min-height: 100vh;

  &::before {
    content: " ";
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: url("../images/online-business-form-bg.jpg") center center
      no-repeat;
    background-size: auto 100%;
    opacity: 0.2;
  }

  &::after {
    content: " ";
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 20px;
    background: url("../images/online-business-form-border.png") center center
      no-repeat;
    background-size: cover;
  }

  .obf-header {
    position: relative;
    z-index: 2;
  }

  .obf-main {
    position: relative;
    z-index: 2;
    padding-bottom: 2rem;
  }

  .container {
    position: relative;
    z-index: 2;
    padding: 2rem;
    width: 100%;
    max-width: 120rem;
    margin: 0 auto;
  }

  .logos {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    img {
      flex: 0 1 13rem;
      display: block;
      max-width: 13rem;

      &[src=""] {
        display: none;
      }
    }
  }

  h1 {
    text-align: center;
    margin-bottom: 0;
    font-weight: 700;
  }

  .add-new-wrap {
    display: inline-block;
    margin-right: 2rem;
  }
}
