.loading-wrap {
  overflow: hidden;
  width: 100%;
  min-height: calc(100vh - 7rem);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  background-color: #f7f7f7;

  &.full {
    min-height: 100vh;
  }

  &.short {
    min-height: 0;
    justify-content: flex-start;
  }

  .modal & {
    min-height: 20rem;
    background-color: transparent;
  }
}

.loader {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 8rem;
  height: 6rem;
}

.small .loader {
  height: 3rem;
}

.loader > div {
  background-color: #838c8e;
  height: 100%;
  width: 1rem;
  animation: scale 1.2s infinite ease-in-out;
}

.loader .rect-2 {
  animation-delay: -1.1s;
}

.loader .rect-3 {
  animation-delay: -1s;
}

.loader .rect-4 {
  animation-delay: -0.9s;
}

.loader .rect-5 {
  animation-delay: -0.8s;
}

@keyframes scale {
  0%,
  40%,
  100% {
    transform: scaleY(0.6);
  }
  20% {
    transform: scaleY(1);
  }
}
