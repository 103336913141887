.contact-details-form {
  .contacts-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5rem;
  }

  .contacts-list-item {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 0 1.5rem 3rem;

    .heading {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin: 0 0 1rem;

      strong {
        margin-left: 1rem;
      }
    }

    h4 {
      margin: 0;
    }

    .delete {
      margin-left: 1rem;
      color: $error;
    }
  }

  .add-new-wrap {
    display: inline-block;
    margin-right: 2rem;
  }

  @media (min-width: 768px) {
    .contacts-list-item {
      flex: 0 1 50%;
      max-width: 50%;
    }
  }
}
