.button {
  display: inline-block;
  padding: 1.5rem 1.5rem;
  max-width: 30rem;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 5rem;
  color: #fff;
  background-color: $primary;
  border: 2px solid $primary;
  transition: background-color 0.15s ease, border-color 0.15s ease;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: darken($primary, 10%);
      border-color: darken($primary, 10%);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.secondary {
    color: $gray-500;
    background-color: transparent;
    border: 2px solid $gray-500;
    transition: border-color 0.15s ease, color 0.15s ease;

    &:not(:disabled) {
      &:hover,
      &:focus {
        color: $gray-800;
        border-color: $gray-800;
        background-color: transparent;
      }
    }
  }

  &.error {
    color: $error;
    background-color: transparent;
    border: 2px solid $error;
    transition: background-color 0.15s ease, border-color 0.15s ease,
      color 0.15s ease;

    &:not(:disabled) {
      &:hover,
      &:focus {
        color: $gray-100;
        border-color: $error;
        background-color: $error;
      }
    }
  }

  &.small {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  & + & {
    margin-left: 1rem;
  }

  @media (min-width: 992px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
