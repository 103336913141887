html {
  box-sizing: border-box;
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

$base-font-size: 1.2rem;

body {
  font-size: $base-font-size;
  line-height: 1.5;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, Helvetica,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font: inherit;
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

input,
select,
textarea {
  appearance: none;
  border-radius: 0;
  border: 0;
  font-size: 1.4rem;

  &::-ms-clear {
    display: none;
  }

  &:focus {
    outline: none;
  }
}

a,
button {
  color: inherit;
  text-decoration: none;
  user-select: none;
  text-align: left;

  &:hover {
    cursor: pointer;
  }
}

button {
  appearance: none;
  background-color: transparent;
  border: 0;
  padding: 0;
}

a:focus,
button:focus {
  outline: 0;
}

img,
svg {
  display: block;
  max-width: 100%;
}

// Typography
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  margin: 0 0 1rem;
  font-weight: 300;
  line-height: 1.2;
}

h1 {
  font-size: $base-font-size * 2;
}

h2 {
  font-size: $base-font-size * 1.8;
}

h3 {
  font-size: $base-font-size * 1.6;
}

h4 {
  font-size: $base-font-size * 1.4;
}

h5 {
  font-size: $base-font-size * 1.2;
}

h6 {
  font-size: $base-font-size * 1;
}

p {
  margin: 0 0 1rem;
}

i,
em {
  font-style: italic;
}

b,
strong {
  font-weight: 700;
}

ul.default-list {
  list-style-type: disc;
  list-style-position: inside;
}

ol.default-list {
  list-style-type: decimal;
  list-style-position: inside;
}

.MuiSnackbar-root {
  max-width: calc(100% - 48px);
}

.MuiTypography-root {
  &.MuiSnackbarContent-root {
    font-size: $base-font-size;
  }

  &.MuiSnackbarContent-root {
    padding: 3px 8px;
  }

  &.SnackbarItem-lessPadding-9 {
    padding-left: 10px;
  }
}
