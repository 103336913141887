.marketing-activities {
  margin-bottom: 1rem;

  legend {
    margin: 0 0 0.5rem;
    font-size: 1.4rem;
    font-weight: normal;
  }

  .activities {
    display: flex;
    flex-wrap: wrap;

    > div {
      flex: 0 1 auto;
      padding-right: 1.5rem;
    }
  }
}
