// Basic Grid
.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
}

.align-items-center {
  align-items: center;
}

.col {
  flex: 0 1 100%;
  padding: 0 1.5rem;
  min-height: 1px;

  &.col-md-6 {
    flex: 1 1 100%;
  }

  @media (min-width: 768px) {
    &.col-md-6 {
      flex: 0 1 50%;
      max-width: 50%;

      &:nth-child(2) {
        text-align: right;
      }
    }
  }
}
